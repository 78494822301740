import './styles.css';
import {TeamBild} from "./TeamBild";
import {render} from "preact";
import {ApiServer} from "../common/ApiServer";
import {getKatalogServerUrl} from "../common/config";

const mountSelector = document.currentScript && document.currentScript.dataset.mountSelector;

if (!mountSelector) {
    console.error("Fehlender Mount-Selector für TeamBild-Script!");
} else {
    document.addEventListener("DOMContentLoaded", () => {
        const mountingpoints = document.querySelectorAll(mountSelector);
        Array.from(mountingpoints).map((root: HTMLElement) => {
            let app = (
                <ApiServer url={getKatalogServerUrl()}>
                    <TeamBild/>
                </ApiServer>
            )
            root.innerHTML = '';
            render(app, root);
        });
    })
}
