import {Trainer} from "../common/Veranstaltung";
import {useEffect, useMemo, useState} from "preact/compat";
import {trainerKopfGroßUrl, trainerKopfPlatzhalterGroßUrl} from "../common/settings";
import {Aktion} from "./model";

interface QuadratProps {
    trainer: Trainer
    dispatch: (aktion: Aktion) => void
}

const transparentClass = "transparent";
const opakClass = "opak"

export function TrainerBild({trainer, dispatch}: QuadratProps) {
    const [sichtbar, macheSichtbar] = useState(false);
    const className = useMemo(() => sichtbar ? opakClass : transparentClass, [sichtbar])
    useEffect(() => {
        const sichtbarDauer = Math.random() * 4000 + 3000;
        const fertigDauer = sichtbarDauer + 3000;
        setTimeout(() => macheSichtbar(true));
        const ausblendeTimeout = setTimeout(() => macheSichtbar(false), sichtbarDauer);
        const fertigTimeout = setTimeout(() => dispatch(["entferne", trainer.id]), fertigDauer)
        return () => {
            clearTimeout(ausblendeTimeout);
            clearTimeout(fertigTimeout);
            dispatch(["entferne", trainer.id])
        }
    }, [])
    return (
        <img className={className}
             src={trainerKopfGroßUrl(trainer.id)}
             // src={trainerKopfPlatzhalterGroßUrl}
             alt={`${trainer.vorname} ${trainer.nachname}`}
             onError={(ev) => (ev.target as HTMLImageElement).src = trainerKopfPlatzhalterGroßUrl}/>
    );
}