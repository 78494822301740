import {Guid} from "../common/Veranstaltung";

type Index = number;

export function nachbarn(zeilen: number, spalten: number, index: number): Index[] {
    if (zeilen <= 0) throw new RangeError("Zeilen müssen positiv sein!");
    if (spalten <= 0) throw new RangeError("Spalten müssen positiv sein!");
    const arrayGröße = zeilen * spalten;
    if (index < 0 || index >= arrayGröße) throw new RangeError('Index out of Bounds');
    const indexZeile = Math.floor(index / spalten);
    const indexSpalte = index % spalten;
    const result = [];
    if (indexZeile > 0) result.push(index - spalten);
    if (indexSpalte > 0 ) result.push(index - 1);
    if (indexSpalte + 1 < spalten) result.push(index + 1);
    if (indexZeile + 1 < zeilen) result.push(index + spalten);
    return result;
}

export type Feld<T> = (T | null)[]

export function freieIndizes<T>(zeilen: number, spalten: number, feld: Feld<T>): Index[] {
    if(feld.length !== zeilen * spalten) throw new Error("Feld passt nicht zu den Dimensionen");
    const result: Index[] = [];
    feld.map((v, i) => {
        if(v === null && nachbarn(zeilen, spalten, i).every(i => feld[i] === null)) result.push(i);
    })
    return result;
}

export type Sample<T> = [T, Index] | [undefined, undefined]

export function sample<T>(array: T[]): Sample<T>{
    if(array.length == 0) return [undefined, undefined]
    const index = Math.floor(Math.random() * array.length);
    return [array[index], index];
}

export type Kandidat = Guid
export type Kandidaten = Kandidat[]
export type KopfFeld = {feld: Feld<Guid>, zeilen: number, spalten: number}
export type Zustand = [Kandidaten, KopfFeld]

export function platziereKandidat(zustand: Zustand): Zustand{
    const [kandidaten, feld] = zustand
    const [kandidat, kIndex] = sample(kandidaten);
    const freieFeldIndizes = freieIndizes(feld.zeilen, feld.spalten, feld.feld);
    const [freiesFeldIndex] = sample(freieFeldIndizes);
    if (kandidat === undefined || freiesFeldIndex === undefined) return zustand
    const neueKandidaten = [...kandidaten];
    neueKandidaten.splice(kIndex, 1);
    const neuesFeld = {...feld};
    neuesFeld.feld[freiesFeldIndex] = kandidat;
    return [neueKandidaten, neuesFeld];
}

export function entferneKandidat(kandidat: Guid, zustand: Zustand): Zustand {
    const [kandidaten, feld] = zustand;
    const feldIndex = feld.feld.indexOf(kandidat);
    if (feldIndex === -1) return zustand
    const neuesFeld = [...feld.feld]
    neuesFeld[feldIndex] = null
    return [[...kandidaten, kandidat], {...feld, feld: neuesFeld}]
}

export type Aktion =
    | ["platziere"]
    | ["entferne", Kandidat]
    | ["neueKandidaten", Kandidaten]

export function reducer (zustand: Zustand, aktion: Aktion): Zustand {
    switch(aktion[0]){
        case "platziere":
            return platziereKandidat(zustand);
        case "entferne":
            return entferneKandidat(aktion[1], zustand);
        case "neueKandidaten":
            const [, kandidaten] = aktion;
            const [, feld] = zustand
            return [kandidaten, feld];
    }
}
