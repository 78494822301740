import {LadeZustand, useAktiveTrainerSofort} from "../common/hooks";
import {Kopfzeile} from "./Kopfzeile";
import {Reihe} from "./Reihe";
import {Kandidaten, KopfFeld, reducer} from "./model";
import {useEffect, useMemo, useReducer} from "preact/compat";
import {Trainer} from "../common/Veranstaltung";

const idGudrun = "1f4b2193-68b4-41f6-b17b-00c214a6796d";
const idMartina = "7f64a707-75db-4cf2-84f9-7fdd64bdb2fe";

function kandidatenAusTrainern(trainerListe: Trainer[]): Kandidaten {
    return trainerListe.map(t => t.id).filter(id => id != idGudrun && id != idMartina);
}

const StartFeld: KopfFeld = {zeilen: 3, spalten: 5, feld: [
            null, null, null, null, null,
            null, null, null, null, null,
            null, null, null, null, null
    ]};

export function TeamBild() {
    const [ladezustand, geladeneTrainer] = useAktiveTrainerSofort()
    const [state, dispatch] = useReducer(reducer, [[], StartFeld]);
    const [, feld] = state;
    const trainerFeld = useMemo(
        () => feld.feld.map(maybeId => (geladeneTrainer?.find(t => t.id === maybeId)) || null),
        [geladeneTrainer, feld]);

    useEffect(() => {
        if (ladezustand === LadeZustand.Fertig) {
            dispatch(["neueKandidaten", kandidatenAusTrainern(geladeneTrainer)]);
            dispatch(["platziere"]);
            const interval = setInterval(() => {
                dispatch(["platziere"]);
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [ladezustand, dispatch]);

    return (
        <div className="teambild">
            <Kopfzeile/>
            <Reihe farben={["#f79b3c", "#588bd0", "#fffd6f", "#5ac644", "#f25d4d"]}
                   trainer={trainerFeld.slice(0, 5)}
                   dispatch={dispatch}/>
            <Reihe farben={["#f8cdfd", "#5ac644", "#f25d4d", "#fffd9c", "#f49dfb"]}
                   trainer={trainerFeld.slice(5, 10)}
                   dispatch={dispatch}/>
            <Reihe farben={["#fffd38", "#c06efa", "#d2fa4b", "#588bd0", "#f79b3c"]}
                   trainer={trainerFeld.slice(10)}
                   dispatch={dispatch}/>
        </div>
    );
}