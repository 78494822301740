import {trainerKopfPlatzhalterGroßUrl} from "../common/settings";

export function Kopfzeile() {
    return (
        <div className="kopf">
            <img className="trainer"
                 src="/trainerkoepfe/1f4b2193-68b4-41f6-b17b-00c214a6796d_large.jpg"
                 alt="Gudrun Heinrichmeyer"
                 onError={(ev) => (ev.target as HTMLImageElement).src = trainerKopfPlatzhalterGroßUrl}/>
            <img className="nlp" src="nlp.svg" alt="nlp"/>
            <img className="trainer"
                 src="/trainerkoepfe/7f64a707-75db-4cf2-84f9-7fdd64bdb2fe_large.jpg"
                 alt="Martina Tönnishoff"
                 onError={(ev) => (ev.target as HTMLImageElement).src = trainerKopfPlatzhalterGroßUrl}/>
        </div>
    );
}