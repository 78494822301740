import {Trainer} from "../common/Veranstaltung";
import {TrainerBild} from "./TrainerBild";
import {Aktion} from "./model";

interface ReihenProps {
    farben: string[]
    trainer: (Trainer | null)[]
    dispatch: (aktion: Aktion) => void
}

export function Reihe(props: ReihenProps) {
    return (
        <div className="reihe">
            {props.farben.map((farbe, index) => {
                const trainer = props.trainer[index] ?? undefined;
                return (
                <div class="feld" key={trainer}>
                    <div className="quadrat" style={`background-color: ${farbe};`}>
                        {trainer ? <TrainerBild trainer={trainer} dispatch={props.dispatch}/> : null}
                    </div>
                </div>
                );
            })}
        </div>
    );
}